/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

const b2cPoliciesDev = {
	names: {
		signUpSignIn: "B2C_1_SiUpIn",
		forgotPassword: "B2C_1_SSPR",
		editProfile: "B2C_1_LocalProfileEdit"
	},
	authorities: {
		signUpSignIn: {
			authority: "https://journeysinparentingdev.b2clogin.com/journeysinparentingdev.onmicrosoft.com/B2C_1_SiUpIn",
		},
		forgotPassword: {
			authority: "https://journeysinparentingdev.b2clogin.com/journeysinparentingdev.onmicrosoft.com/B2C_1_SSPR",
		},
		editProfile: {
			authority: "https://journeysinparentingdev.b2clogin.com/journeysinparentingdev.onmicrosoft.com/B2C_1_LocalProfileEdit"
		}
	},
	authorityDomain: "journeysinparentingdev.b2clogin.com"
};
const b2cPoliciesLive = {
	names: {
		signUpSignIn: "B2C_1_SiUpIn",
		forgotPassword: "B2C_1_SSPR",
		editProfile: "B2C_1_LocalProfileEdit"
	},
	authorities: {
		signUpSignIn: {
			authority: "https://journeysinparenting.b2clogin.com/journeysinparenting.onmicrosoft.com/B2C_1_SiUpIn",
		},
		forgotPassword: {
			authority: "https://journeysinparenting.b2clogin.com/journeysinparenting.onmicrosoft.com/B2C_1_SSPR",
		},
		editProfile: {
			authority: "https://journeysinparenting.b2clogin.com/journeysinparenting.onmicrosoft.com/B2C_1_LocalProfileEdit"
		}
	},
	authorityDomain: "journeysinparenting.b2clogin.com"
};

const configCreators = {
	"http://localhost:3000": function () {
		const apiConfig = {
			b2cScopes: ["https://journeysinparentingdev.onmicrosoft.com/funcapi/booking_access"],
			webApi: "http://localhost:7071"
		};
		const authConfig = {
			msalConfig: {
				auth: {
					clientId: "29e85215-d112-44d2-8446-5b79190852d4",
					authority: b2cPoliciesDev.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
					knownAuthorities: [b2cPoliciesDev.authorityDomain], // You must identify your tenant's domain as a known authority.
					redirectUri: "http://localhost:3000", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
				},
				cache: {
					cacheLocation: 'localStorage',
					storeAuthStateInCookie: true,
				}
			},
			loginRequest: {
				scopes: [...apiConfig.b2cScopes],
			},
			tokenRequest: {
				scopes: [...apiConfig.b2cScopes],
				forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
			},
		};
		const appInsights = {
			instrumentationKey: "e5f94e6c-5be0-425d-acd8-372600b60c44",
		}
		const portal = {
			baseUrl: "https://bookings-dev.emilieleeks.com/"
		}
		return {
			apiConfig,
			b2cPolicies: b2cPoliciesDev,
			authConfig,
			appInsights,
			portal,
			stripeKey: 'pk_test_mECzAP5zHgK2VkriGAe7XStt',
			accountSelectorEnvironment: "journeysinparentingdev.b2clogin.com"
		}
	},
	"https://bookings.dev.emilieleeks.com": function () {
		const apiConfig = {
			b2cScopes: ["https://journeysinparentingdev.onmicrosoft.com/funcapi/booking_access"],
			webApi: "https://elcombookingsdev.azurewebsites.net"
		};
		const authConfig = {
			msalConfig: {
				auth: {
					clientId: "29e85215-d112-44d2-8446-5b79190852d4",
					authority: b2cPoliciesDev.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
					knownAuthorities: [b2cPoliciesDev.authorityDomain], // You must identify your tenant's domain as a known authority.
					redirectUri: "https://bookings.dev.emilieleeks.com", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
				},
				cache: {
					cacheLocation: 'localStorage',
					storeAuthStateInCookie: true,
				}
			},
			loginRequest: {
				scopes: [...apiConfig.b2cScopes],
			},
			tokenRequest: {
				scopes: [...apiConfig.b2cScopes],
				forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
			},
		};
		const appInsights = {
			instrumentationKey: "e5f94e6c-5be0-425d-acd8-372600b60c44",
		}
		const portal = {
			baseUrl: "https://bookings-dev.emilieleeks.com/"
		}
		return {
			apiConfig,
			b2cPolicies: b2cPoliciesDev,
			authConfig,
			appInsights,
			portal,
			stripeKey: 'pk_test_mECzAP5zHgK2VkriGAe7XStt',
			accountSelectorEnvironment: "journeysinparentingdev.b2clogin.com"
		}
	},
	"https://bookings.staging.emilieleeks.com": function () {
		const apiConfig = {
			b2cScopes: ["https://journeysinparenting.onmicrosoft.com/bookings-api/booking_access"],
			webApi: "https://elcombookingsstag.azurewebsites.net"
		};
		const authConfig = {
			msalConfig: {
				auth: {
					clientId: "30d9dfb8-b674-440d-9105-c78b187562d3",
					authority: b2cPoliciesLive.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
					knownAuthorities: [b2cPoliciesLive.authorityDomain], // You must identify your tenant's domain as a known authority.
					redirectUri: "https://bookings.staging.emilieleeks.com", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
				},
				cache: {
					cacheLocation: 'localStorage',
					storeAuthStateInCookie: true,
				}
			},
			loginRequest: {
				scopes: [...apiConfig.b2cScopes],
			},
			tokenRequest: {
				scopes: [...apiConfig.b2cScopes],
				forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
			},
		};
		const appInsights = {
			instrumentationKey: "cc72d075-81f2-4c9a-874d-bd057b43fe3a",
		}
		const portal = {
			baseUrl: "https://bookings-staging.emilieleeks.com/"
		}
		return {
			apiConfig,
			b2cPolicies: b2cPoliciesLive,
			authConfig,
			appInsights,
			portal,
			stripeKey: 'pk_live_lLvnrCNbYQsV5PoIQhykej4l',
			accountSelectorEnvironment: "journeysinparenting.b2clogin.com"
		}
	},
	"https://bookings.emilieleeks.com": function () {
		const apiConfig = {
			b2cScopes: ["https://journeysinparenting.onmicrosoft.com/bookings-api/booking_access"],
			webApi: "https://elcombookingslive.azurewebsites.net"
		};
		const authConfig = {
			msalConfig: {
				auth: {
					clientId: "30d9dfb8-b674-440d-9105-c78b187562d3",
					authority: b2cPoliciesLive.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
					knownAuthorities: [b2cPoliciesLive.authorityDomain], // You must identify your tenant's domain as a known authority.
					redirectUri: "https://bookings.emilieleeks.com", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
				},
				cache: {
					cacheLocation: 'localStorage',
					storeAuthStateInCookie: true,
				}
			},
			loginRequest: {
				scopes: [...apiConfig.b2cScopes],
			},
			tokenRequest: {
				scopes: [...apiConfig.b2cScopes],
				forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
			},
		};
		const appInsights = {
			instrumentationKey: "a2f3578a-580f-412c-8be3-625957dbf52f",
		}
		const portal = {
			baseUrl: "https://bookings.emilieleeks.com/"
		}
		return {
			apiConfig,
			b2cPolicies: b2cPoliciesLive,
			authConfig,
			appInsights,
			portal,
			stripeKey: 'pk_live_lLvnrCNbYQsV5PoIQhykej4l',
			accountSelectorEnvironment: "journeysinparenting.b2clogin.com"
		}
	},
}


export const Config = {
	current: function () {
		const origin = window.location.origin;
		const configCreator = configCreators[origin];
		if (configCreator == null) {
			console.log(`Config not found for origin '${origin}'`);
			throw new Error(`Config not found for origin '${origin}'`);
		}
		// console.log(`Loading config for origin '${origin}'`)
		return configCreator();
	}
}